@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'bootstrap/scss/reboot';

@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/utilities/background';
@import 'bootstrap/scss/utilities/display';
@import 'bootstrap/scss/utilities/flex';
@import 'bootstrap/scss/utilities/spacing';
@import 'bootstrap/scss/utilities/text';
