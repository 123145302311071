.events-map {
    @include bp-md {
        padding-left: 11.88%; }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include bp-md {
            display: inline-block;
            position: relative; } }

    &__image {
        display: none;
        @include bp-md {
            width: 700px;
            max-width: 50vw;
            filter: none;
            display: inline; } }

    &__label {
        text-align: left;
        width: calc(50% - 7.5px);
        background-color: $primary;
        margin-bottom: 11px;
        color: $white;
        font-size: 10px;
        padding: 25px 20px;
        @include bp-md {
            position: absolute;
            width: auto;
            height: auto;
            display: inline-block;
            background: transparent;
            margin-bottom: 0;
            color: $primary;
            font-size: 16px;
            padding: 0;

            &--gdansk {
                top: 1%;
                left: -19%; }

            &--kielce {
                top: 35%;
                left: -19%; }

            &--krakow {
                top: 71%;
                left: -19%; }

            &--lodz {
                top: -8%;
                right: -33%; }

            &--warszawa {
                top: 28%;
                right: -33%; }

            &--katowice {
                top: 62%;
                right: -33%; } }

        p {
            margin: 0; } }

    &__logo {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
        fill: $white;
        @include bp-md {
            height: 70px;
            width: 220px;
            margin-bottom: 0;
            fill: $primary; } } }
