@mixin bp-xs {
    @include media-breakpoint-up(xs) {
        @content; } }

@mixin bp-sm {
    @include media-breakpoint-up(sm) {
        @content; } }

@mixin bp-md {
    @include media-breakpoint-up(md) {
        @content; } }

@mixin bp-lg {
    @include media-breakpoint-up(lg) {
        @content; } }

@mixin bp-xl {
    @include media-breakpoint-up(xl) {
        @content; } }

@mixin clear-list-style {
    list-style: none;
    margin: 0;
    padding: 0; }

@mixin font-bold {
    font-family: spacemono-bold; }

@mixin font-medium {
    font-family: chakrapetch-medium; }

@mixin responsive-font($font-size) {
    @if $font-size > 24 {
        font-size: #{$font-size * .73}px; }
    @else if $font-size > 16 {
        font-size: #{$font-size * .7}px; }
    @else {
        font-size: #{$font-size * .95}px; }
    @include bp-md {
        font-size: #{$font-size}px; } }

@mixin font-icon-props {
    font-family: "segfault-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: none;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em; }

@mixin reset-list-style {
    list-style: none;
    margin: 0;
    padding: 0; }
