.instagram-feed {
    position: relative;
    display: inline-block;

    &:hover {
        .icon-instagram {
            transform: scale(1.5); } }

    .icon-instagram {
        position: absolute;
        z-index: 1;
        top: 13px;
        left: 15px;
        color: $accent;
        transition: .5s; } }
