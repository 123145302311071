.footer {
    padding: 15px 30px;
    position: relative;
    overflow: hidden;

    @include bp-xl {
        padding: 30px 60px; }

    .icon-instagram {
        font-size: 20px;
        @include bp-md {
            font-size: 28px; } }

    .logo {
        z-index: 1; }

    &__socials {
        @include bp-lg {
            position: absolute;
            right: 30px;
            top: 20px; }
        @include bp-xl {
            top: 70px;
            right: 60px; } }

    .list__item {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            margin-left: 0;
            &::before {
                display: none; } } } }
