.header {
    padding: 15px 30px;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    background: $white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 5px 1px;
    transition: transform .3s ease-in-out;

    // +bp-xl
    //  // padding: 20px 60px

    &--hidden {
        transform: translateY(-100%);
        @include bp-md {
            transform: none; } } }

.logo {
    width: 150px;
    height: 50px;
    position: relative;
    z-index: 101;

    @include bp-xl {
        width: 230px;
        height: 77px; } }

.menu {
    $menu: &;

    &__container {
        display: none;

        @include bp-lg {
            display: flex;
            align-items: center;

            ul {
                display: flex;
                align-items: center; }

            .menu-items {
                li {
                    margin-right: 1em;
                    float: left; } } }

        #{$menu}--toggled & {
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;
            background-color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 100px;
            padding-bottom: 120px;

            .button {
                &:not(.button--link) {
                    width: 213px; } } } }

    &__button {
        appearance: none;
        border: none;
        background-color: transparent;
        width: 60px;
        height: 45px;
        position: relative;
        z-index: 101;
        transform: scale(.75);

        @include bp-lg {
            display: none; }

        &:focus {
            outline: none; }

        span {
            display: block;
            position: absolute;
            height: 5px;
            width: 100%;
            background: $primary;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
            transform-origin: left center;

            &:nth-child(1) {
                top: 0;

                #{$menu}--toggled & {
                    transform: rotate(45deg);
                    top: -3px;
                    left: 8px; } }

            &:nth-child(2) {
                top: 18px;

                #{$menu}--toggled & {
                    width: 0;
                    opacity: 0; } }

            &:nth-child(3) {
                top: 36px;

                #{$menu}--toggled & {
                    transform: rotate(-45deg);
                    top: 39px;
                    left: 8px; } } } } }
