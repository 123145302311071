.dropdown {
    position: relative;

    &:hover {
        .dropdown__list {
            transform: translateY(0);
            opacity: 1;
            pointer-events: all;
            box-shadow: 0 5px 11px 0px rgba(0, 0, 0, 0.1); } }

    &__button {
        &::before {
            top: calc(50% - 6px); } }

    &__list {
        position: absolute;
        top: 100%;
        left: 8px;
        right: 8px;
        background-color: $primary;
        color: $white;
        @include reset-list-style;
        padding: 0 16px;
        text-align: center;
        transform: translateY(-6px);
        opacity: 0;
        transition: transform .15s ease-out;
        pointer-events: none;
        flex-direction: column; }

    &__item {
        border-bottom: 2px solid $white;
        font-size: 10px;
        padding: 5px 0;
        @include bp-md {
            padding: 13px 0;
            font-size: 16px; }

        &:last-of-type {
            border: none; } } }
