$greeny-blue: #5bc1a7;
$greyish: #b3b3b3;
$black: #312d2a;
$sand: #e9c46a;
$ocean: #01758f;

$primary:       $ocean !default;
$secondary:     $greeny-blue !default;
$accent:        $sand !default;
$light:         $greyish !default;
$dark:          $black !default;

$theme-colors: ("accent":$accent) !default; // extend bootstrap theme colors map
$spacers: (80: 80px, 100: 100px);

$font-sizes: (60:1.2, 48:1.21, 36:1.17, 34:1.3, 24:1.5, 22:1.36, 20:1.5, 18:1.44, 16:1.5, 14:1.57, 12:1.33);
