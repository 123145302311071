@font-face {
    font-family: 'chakrapetch-medium';
    src: url('../fonts/chakrapetch-medium-webfont.woff2') format('woff2'), url('../fonts/chakrapetch-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal; }

@font-face {
    font-family: 'spacemono-bold';
    src: url('../fonts/spacemono-bold-webfont.woff2') format('woff2'), url('../fonts/spacemono-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal; }

@font-face {
  font-family: 'segfault-icons';
  src: url('../fonts/segfault-icons.woff2?94052570') format('woff2'), url('../fonts/segfault-icons.woff?94052570') format('woff');
  font-weight: normal;
  font-style: normal; }
