body {
    @include font-medium;
    line-height: 1.5;
    @include responsive-font(16);
    color: $primary;
    padding-top: 90px;

    @include bp-xl {
        padding-top: 125px; } }

img {
    @include img-fluid();
    filter: grayscale(1);
    transition: filter .2s ease-in-out;

    &:hover {
        filter: grayscale(0); } }

strong {
    @include font-bold; }

.container {
    width: 1150px;
    max-width: 100%;
    padding: 0 30px;

    @include bp-md {
        padding: 0 15px; } }

a {
    color: inherit;

    &:hover {
        color: inherit;
        text-decoration: none; } }
