.boxed {
    position: relative;
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
    padding: 30px 30px 40px;

    &--primary {
        background-color: $primary; }

    &--secondary {
        background-color: $secondary; }

    @include bp-md {
        background: transparent;
        width: auto;
        max-width: none;
        margin: 0;
        padding: 0; } }
