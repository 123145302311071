.testimonial {
    height: 300px;
    text-align: center;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--mid {
        max-width: 450px;
        margin: 0 auto; }
    &--small {
        width: 100%;
        height: 0;
        padding: 0;
        padding-bottom: 100%;
        position: relative;
        overflow: hidden;

        .testimonial__content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
                margin-bottom: 5px;
                font-size: 10px;

                @include bp-lg {
                    font-size: 16px; }

                &:last-of-type {
                    margin-bottom: 0;

                    &:not(:first-of-type) {
                        font-size: 8px;

                        @include bp-lg {
                            font-size: 10px; } } } } } } }
