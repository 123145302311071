.tweet {
    height: calc(100% - 24px);
    min-height: 280px;
    overflow: hidden;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    word-break: break-word;

    p:last-of-type {
        margin-bottom: 0; }

    a {
        color: $primary; } }
