.badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 13px;
    background-color: $white;
    color: $primary;
    font-size: 12px;
    @include font-bold;
    text-align: center;
    white-space: nowrap;

    @include bp-md {
        height: 24px; }


    &--pill {
        border-radius: 40px;
        padding: 0 20px; }

    &--circle {
        border-radius: 50%;
        width: 20px;
        padding: 0;

        @include bp-md {
            width: 24px; } }

    &--primary {
        background-color: $primary;
        color: $white; }

    &--secondary {
        background-color: $secondary;
        color: $white; }

    &--accent {
        background-color: $accent;
        color: $white; }

    &--disabled {
        background-color: $light;
        color: $white; } }
