.avatar {
    position: relative;
    margin-top: 40px;
    border-radius: 50%;
    border: 4px solid $primary;
    margin: 0 auto;
    width: 100px;
    height: 100px;

    &--accent {
        border-color: $accent; }

    &--secondary {
        border-color: $secondary; }

    &--small {
        width: 40px;
        height: 40px;
        margin-top: 0;

        .avatar__photo {
            border-width: 2px; } }

    &--large {
        @include bp-md {
            width: 140px;
            height: 140px;
            max-width: none; } }

    &--extra-large {
        @include bp-md {
            width: 260px;
            max-width: 100%;
            height: auto; } }

    &__photo {
        display: block;
        border-radius: 50%;
        width: 100%;
        height: 100%; }

    &__link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 120px;
        margin: 0 auto;
        text-decoration: none;

        &:focus {
            outline: none; } } }
