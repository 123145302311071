[class^="icon-"]:before, [class*=" icon-"]:before {
    @include font-icon-props; }

.icon-play:before {
    content: '\e800'; }
.icon-youtube:before {
    content: '\e801'; }
.icon-arrow:before {
    content: '\e802'; }
.icon-chevron-down:before {
    content: '\e803'; }
.icon-facebook:before {
    content: '\e804'; }
.icon-instagram:before {
    content: '\f16d'; }
.icon-linkedin:before {
    content: '\e806'; }
.icon-twitter:before {
    content: '\e807'; }
.icon-twitter-simple:before {
    content: '\e808'; }
.icon-list-decorator:before {
    content: '\e809'; }
.icon-arrow-back:before {
    content: '\e80a'; }
.icon-hand-point-right:before {
    content: '\261b'; }

.icon-podcast {
    display: inline-block;
    height: 40px;
    width: 40px;
    filter: sepia() saturate(100%) hue-rotate(60deg);
    margin-right: 0.4em; }

.icon-spotify {
    background: url(../images/podcast_spotify.png) no-repeat;
    background-size: contain; }
.icon-overcast {
    background: url(../images/podcast_overcast.png) no-repeat;
    background-size: contain; }
.icon-apple {
    background: url(../images/podcast_apple.png) no-repeat;
    background-size: contain; }
.icon-google {
    background: url(../images/podcast_google.png) no-repeat;
    background-size: contain; }
.icon-rss {
    background: url(../images/podcast_rss.jpg) no-repeat;
    background-size: contain; }
