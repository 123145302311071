.gallery {
    overflow: hidden;
    padding-left: 10px;
    padding-top: 10px;

    @include bp-md {
        padding-left: 0;
        padding-top: 0; }

    &__image {
        display: inline-block;
        width: 100%; }

    [class*="col-"] {
        padding: 0 10px; }

    .row {
        margin: 0 -10px; }

    .container {
        padding-left: 0; } }
