.event {
    $event: &;
    position: relative;

    &--detailed {
        max-width: 300px;
        width: 100%;
        background: $white;

        @include bp-md {
            max-width: calc(50% - 40px);
            margin-right: 30px; }
        @include bp-lg {
            max-width: calc(33.33% - 40px); }

        #{$event} {
            &__wrapper {
                height: 380px;
                max-width: 300px;
                @include bp-md {
                    max-width: none; } }

            &__content {
                padding: 40px 30px;
                @include bp-xl {
                    padding: 40px; } }

            &__logo {
                height: 55px; } } }

    // &--upcoming
    //     background-color: $secondary
    //     max-width: 319px
    //     width: 100%
    //     height: 330px
    //     margin: 0 auto
    //     padding: 50px 0
    //     +bp-md
    //         width: 25%
    //         padding: 0
    //         background-color: transparent
    //         height: auto
    //         margin: 0

    //     #{$event}
    //         &__wrapper
    //             height: 330px
    //             +bp-md
    //                 height: auto

    //     #{$event}__text
    //         height: 95px

    // &--for-sale
    //     height: 300px
    //     background-color: $primary
    //     padding: 50px 0
    //     +bp-md
    //         background-color: transparent
    //         height: auto
    //         width: 25%
    //         padding: 0

    //     #{$event}
    //         &__text
    //             height: 75px
    //             padding-bottom: 0
    //             justify-content: center
    //         &__wrapper
    //             height: 300px
    //             +bp-md
    //                 height: auto

    &--single {
        background-color: $primary;
        margin: 0 auto;
        text-align: center;
        max-width: 450px;
        height: 330px;
        color: $white;
        padding: 50px;

        @include bp-md {
            background-color: transparent;
            text-align: left;
            margin-left: 0;
            color: $primary;
            height: auto;
            padding: 0; }

        #{$event} {
            &__content {
                text-align: left; }
            &__logo {
                height: 70px;
                max-width: 100%;
                @include bp-md {
                    fill: $primary;
                    width: 311px;
                    height: 90px; } } } }

    &__image {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        filter: grayscale(1); }

    &__gradient-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around; }

    &__logo {
        width: auto;
        height: 70px;
        max-width: 100%;
        fill: white;

        &--primary {
            fill: $primary; }

        &--fixed {
            width: 230px; }

        &--white {
            filter: brightness(400); } }

    &__text {
        min-height: 130px;
        font-size: 1.4em;

        &--small {
            font-size: 0.6em; }

        p {
            margin-bottom: 4px; } }

    .button {
        max-width: 213px;
        margin: 0 auto; }

    .badge {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%); } }
