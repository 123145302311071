.article {
    &__photo {
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%); }

        &--small {
            padding-bottom: 50%;

            img {
                width: 75%;
                left: 50%;
                transform: translate(-50%, -50%); } } } }
